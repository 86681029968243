import Vue from 'vue'
import Vuex from 'vuex'
import User from './user/User'
import firebase from 'firebase'
import Family from './family/Family'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null
    },
    family: {
      data: null
    }
  },
  getters: {
    user (state) {
      return state.user
    },
    family (state) {
      return state.family
    }
  },
  mutations: {
    SET_LOGGED_IN (state, value) {
      state.user.loggedIn = value
    },
    SET_USER (state, data) {
      state.user.data = data
    },
    SET_FAMILY (state, data) {
      state.family.data = data
    }
  },
  actions: {
    fetchUser ({ commit }, user) {
      commit('SET_LOGGED_IN', user !== null)
      if (user) {
        commit('SET_USER', {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          uid: user.uid
        })
      } else {
        commit('SET_USER', null)
      }
    },
    fetchFamily ({ commit }, firebaseUser: firebase.User) {
      if (firebaseUser) {
        if (firebaseUser.displayName !== null && firebaseUser.email !== null && firebaseUser.uid !== null) {
          const family = new Family('')
          const user = new User(firebaseUser.displayName, firebaseUser.email, firebaseUser.uid, family)
          user.GetFamilyId().then((familyId: string) => {
            if (familyId !== null) {
              commit('SET_FAMILY', {
                id: familyId
              })
            }
          })
        }
      } else {
        console.log('No Firebase User available')
      }
    }
  },
  plugins: [vuexLocal.plugin]
})
